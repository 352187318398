export const ratings = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4},
];

export const recoveryEmail = [
  {value: 0, label: "0"},
  {value: 1, label: "1"},
  {value: 2, label: "2"},
  {value: 3, label: "3"},
  {value: -1, label: "Cualquiera"},
];

export const postpone = [
  {value: "F&F", label: "F&F"},
  {value: "1 Mes", label: "1 Mes"},
  {value: "2 Meses", label: "2 Meses"},
  {value: "3 Meses", label: "3 Meses"},
  {value: "Próximo año", label: "Próximo año"},
];

export const commissions = [
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4},
  {value: 5, label: 5},
];

export const user_types = [
  {value: "A", label: "Administrador", type: "Interno"},
  {value: "V", label: "Asesor Interno", type: "Interno"},
  {value: "S", label: "Asesor Externo", type: "Externo"},
  {value: "M", label: "Agencia", type: "Interno"},
];
