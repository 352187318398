import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../../../AbstractElements";
import Request from "../../../../api/httpClient";
import Select from "react-select";
import {ModalFooter} from "reactstrap";
import {toast} from "react-toastify";
import moment from "moment";

const ProspectCreateForm = ({
  modal,
  toggle,
  reload,
  p_desarrollos,
  p_desarrollo,
  fuentes,
  loadingFuentes,
  etapas,
}) => {
  const userId = localStorage.getItem("user_id") || 0;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [presupuesto, setPresupuesto] = useState(0);

  const desarrollos = p_desarrollos.map((item) => {
    return {
      label: item.nomDesarrollo,
      value: item.IDdesarrollo,
    };
  });
  const [desarrollo, setDesarrollo] = useState(
    desarrollos.find((item) => item.value === p_desarrollo.IDdesarrollo)
  );
  const [calificaciones] = useState([
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
    {value: 4, label: 4},
  ]);
  const [calificacion, setCalificacion] = useState(calificaciones[0]);
  const [fuente, setFuente] = useState(fuentes[0]);
  const [departamentos, setDepartamentos] = useState([]);
  const [departamentosSelec, setDepartamentosSelec] = useState(null);
  const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);
  const [etapa, setEtapa] = useState(etapas[0]);
  const [posponerOpciones] = useState([
    {value: "F&F", label: "F&F"},
    {value: "1 Mes", label: "1 Mes"},
    {value: "2 Meses", label: "2 Meses"},
    {value: "3 Meses", label: "3 Meses"},
    {value: "Próximo año", label: "Próximo año"},
  ]);
  const [posponer, setPosponer] = useState(null);

  const request = new Request();

  useEffect(() => {
    if (modal) {
      LimpiarDatos();
    }
  }, [modal]);

  useEffect(() => {
    if (desarrollo) {
      getDepartamentos();
    }
  }, [desarrollo]);

  const LimpiarDatos = () => {
    setNombre("");
    setEmail("");
    setCiudad("");
    setTelefono("");
    setComentarios("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitDisabled(true);

    const fechaHoy = moment().format("YYYY-MM-DD");

    let data = {
      nombre_usuario: localStorage.getItem("Name") || "",
      id_usuario: userId || 0,
      cliente: nombre,
      email: email,
      telefono: telefono,
      ciudad: ciudad,
      budget: presupuesto || null,
      comentarios: comentarios,
      interes: departamentosSelec ? departamentosSelec.label : "",
      origen: fuente.value,
      origen_otro: "",
      broker: 0,
      fechaVencimiento: moment(fechaHoy).add(45, "days").format("YYYY-MM-DD"),
      aprobado: 1,
      importante: 0,
      calificacion: calificacion.value,
      id_desarrollo: desarrollo.value,
      id_embudo: etapa.value,
      posponer: posponer && posponer.value,
      register_id: userId || 0,
    };

    const response = await request.post("/prospectos/prospecto/create", data);

    if (response && !response.error) {
      toggle();
      setIsSubmitDisabled(false);
      reload(true, "Prospecto creado con éxito ", desarrollo.value);
    } else {
      setIsSubmitDisabled(false);
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  //obtener todos los departamentos por desarrollo
  const getDepartamentos = async () => {
    setDepartamentos([]);
    setLoadingDepartamentos(true);
    const data = {
      id_desarrollo: desarrollo.value,
    };
    const response = await request.post("/prospectos/get/departamentos", data);
    if (response && !response.error) {
      if (response && !response.empty) {
        setDepartamentos(
          response.map((dpto) => {
            return {
              value: dpto.pkdepto,
              label: dpto.depto,
            };
          })
        );
      } else {
        setDepartamentos([]);
        setLoadingDepartamentos(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingDepartamentos(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm="12" md="12">
          <div className="project-box" style={{paddingBottom: "5px"}}>
            <div>
              <div>
                <FormGroup className="row">
                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">Nombre:</Label>
                    <Input
                      id="nombre"
                      type="text"
                      onChange={(e) => {
                        setNombre(e.target.value);
                      }}
                      required
                    />
                  </Col>
                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">Email:</Label>
                    <Input
                      id="email"
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </Col>
                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">
                      Teléfono:
                    </Label>
                    <Input
                      id="telefono"
                      type="tel"
                      value={telefono}
                      minLength={10}
                      maxLength={15}
                      onChange={(e) => {
                        const telefono = e.target.value.replace(/[^0-9]/g, "");
                        setTelefono(telefono);
                      }}
                      required
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">Ciudad:</Label>
                    <Input
                      id="ciudad"
                      type="text"
                      onChange={(e) => {
                        setCiudad(e.target.value);
                      }}
                    />
                  </Col>
                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">
                      Desarrollo:
                    </Label>
                    <Select
                      value={desarrollo}
                      options={desarrollos}
                      placeholder={"Seleccione..."}
                      onChange={(desarr) => setDesarrollo(desarr)}
                      required
                    />
                  </Col>

                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">
                      Interesados en:
                    </Label>
                    <Select
                      options={departamentos}
                      value={departamentosSelec}
                      onChange={(depts) => setDepartamentosSelec(depts)}
                      placeholder={"Seleccione..."}
                      isLoading={loadingDepartamentos}
                      isClearable
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">
                      Presupuesto:
                    </Label>
                    <Input
                      id="ciudad"
                      type="text"
                      onChange={(e) => {
                        setPresupuesto(e.target.value);
                      }}
                    />
                  </Col>
                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">
                      Calificación:
                    </Label>
                    <Select
                      value={calificacion}
                      options={calificaciones}
                      placeholder={"Seleccione..."}
                      onChange={(calificacion) => setCalificacion(calificacion)}
                      required
                    />
                  </Col>

                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">
                      Fuente de contacto:
                    </Label>
                    <Select
                      value={fuente}
                      options={fuentes}
                      placeholder={"Seleccione..."}
                      onChange={(fuente) => setFuente(fuente)}
                      isLoading={loadingFuentes}
                      required
                    />
                  </Col>
                  <Col xl="4" sm="12">
                    <Label className="col-form-label text-start">Etapa:</Label>
                    <Select
                      value={etapa}
                      options={etapas}
                      placeholder={"Seleccione..."}
                      onChange={(etapa) => setEtapa(etapa)}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  {etapa && etapa.label === "Seguimiento" && (
                    <Col xl="4" sm="12">
                      <Label className="col-form-label text-start">
                        Posponer hasta:
                      </Label>
                      <Select
                        value={posponer}
                        options={posponerOpciones}
                        placeholder={"Seleccione..."}
                        onChange={(posponer) => setPosponer(posponer)}
                        // required
                      />
                    </Col>
                  )}
                </FormGroup>

                <FormGroup className="row">
                  <Col xl="12">
                    <Label className="col-form-label text-start">
                      Comentarios:
                    </Label>
                    <Input
                      id="comentarios"
                      type="textarea"
                      className="form-control"
                      rows="2"
                      onChange={(e) => {
                        setComentarios(e.target.value);
                      }}
                    />
                  </Col>
                </FormGroup>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ModalFooter>
        <Btn attrBtn={{color: "secondary", onClick: toggle}}>Cancelar</Btn>
        <Btn
          attrBtn={{
            type: "submit",
            color: "primary",
            disabled: isSubmitDisabled,
          }}
        >
          {isSubmitDisabled ? "Guardando..." : "Guardar"}
        </Btn>
      </ModalFooter>
    </Form>
  );
};

export default ProspectCreateForm;
