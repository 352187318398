import React from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonModal from "../UiKits/Modals/common/modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../AbstractElements";
import Select from "react-select";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import useUsersFormEdit from "../../Hooks/users/useUsersFormEdit";
import {commissions, user_types} from "../../Data/options";

const UsuarioFormEdit = ({modal, title, toggle, size, reload, usuario}) => {
  const {
    nombre,
    setNombre,
    email,
    setEmail,
    telefono,
    setTelefono,
    isSubmitDisabled,
    isLoading,
    internalAdvisors,
    tipoUsuario,
    setTipoUsuario,
    comision,
    setComision,
    handleSubmit,
    userRole,
    developments,
    setSelectedDevelopment,
    loadingDevelopments,
    selectedDevelopment,
  } = useUsersFormEdit(usuario, reload, toggle);

  return (
    <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
      <Modal isOpen={modal} toggle={toggle} size={size} centered>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody style={{paddingTop: "2px", paddingBottom: "2px"}}>
            <Row>
              <Col sm="12" md="12">
                <div className="project-box" style={{paddingBottom: "5px"}}>
                  <FormGroup className="row">
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Nombre:
                      </Label>
                      <Input
                        id="nombre"
                        type="text"
                        value={nombre}
                        onChange={(e) => {
                          setNombre(e.target.value);
                        }}
                        required
                      />
                    </Col>
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Email:
                      </Label>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Teléfono:
                      </Label>
                      <Input
                        id="telefono"
                        type="tel"
                        value={telefono}
                        minLength={10}
                        maxLength={10}
                        onChange={(e) => {
                          const telefono = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setTelefono(telefono);
                        }}
                      />
                    </Col>
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Comisión:
                      </Label>
                      <Select
                        value={comision}
                        options={commissions}
                        placeholder={"Seleccione..."}
                        onChange={(comision) => setComision(comision)}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Col xl="12" sm="12">
                      <Label className="col-form-label text-start">
                        Tipo de usuario:
                      </Label>
                      <Select
                        value={tipoUsuario}
                        options={user_types}
                        placeholder={"Seleccione..."}
                        onChange={(tipoUsuario) => setTipoUsuario(tipoUsuario)}
                        required
                      />
                    </Col>
                    {tipoUsuario.value === "M" && userRole === "A" && (
                      <Col sm="12">
                        <Label className="col-form-label text-start">
                          Desarrollos que puede ver:
                        </Label>
                        <Select
                          isMulti
                          name="colors"
                          options={developments}
                          defaultValue={selectedDevelopment}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder={"Seleccione..."}
                          isLoading={loadingDevelopments}
                          onChange={(e) => setSelectedDevelopment(e)}
                        />
                      </Col>
                    )}
                    {tipoUsuario.value === "S" && userRole === "A" && (
                      <Col sm="12">
                        <Label className="col-form-label text-start">
                          Asesor interno:
                        </Label>
                        <Select
                          isDisabled
                          options={internalAdvisors}
                          placeholder={"Seleccionar asesor interno"}
                          value={internalAdvisors.find(
                            (a) => a.id === usuario.parentuser
                          )}
                          isLoading={isLoading}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 3,
                            }),
                          }}
                        />
                      </Col>
                    )}
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{color: "secondary", onClick: toggle}}>Cancelar</Btn>
            <Btn
              attrBtn={{
                type: "submit",
                color: "primary",
                disabled: isSubmitDisabled,
              }}
            >
              {isSubmitDisabled ? "Guardando..." : "Guardar"}
            </Btn>
          </ModalFooter>
        </Form>
      </Modal>
    </CommonModal>
  );
};

export default UsuarioFormEdit;
