import React, {useEffect, useState} from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
} from "reactstrap";
import CommonModal from "../UiKits/Modals/common/modal";
import Select from "react-select";
import {toast} from "react-toastify";
import {MD5} from "crypto-js";
import Request from "../../api/httpClient";
import {Btn} from "../../AbstractElements";
import {commissions, user_types} from "../../Data/options";

const request = new Request();

const UsuarioForm = ({modal, title, toggle, size, reload, rol}) => {
  const userId = localStorage.getItem("user_id") || 0;
  const userRole = localStorage.getItem("Role");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [internalAdvisors, setInternalAdvisors] = useState([]);
  const [selectedInternalAdvisor, setSelectedInternalAdvisor] = useState("");
  const [developments, setDevelopments] = useState([]);
  const [selectedDevelopment, setSelectedDevelopment] = useState([]);
  const [loadingDevelopments, setLoadingDevelopments] = useState(false);

  const [tipoUsuario, setTipoUsuario] = useState(
    user_types.find((user) => user.value === rol)
  );

  const [comision, setComision] = useState(commissions[0]);

  const generarPassword = () => {
    var cadena =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var password = "";
    for (var i = 0; i < 6; i++) {
      password += cadena.charAt(Math.floor(Math.random() * cadena.length));
    }
    return password;
  };

  const [contrasenna, setContrasenna] = useState(generarPassword());

  const LimpiarDatos = () => {
    setNombre("");
    setEmail("");
    setTelefono("");
  };

  const fetchInternalAdvisors = async () => {
    setInternalAdvisors([]);
    setIsLoading(true);

    const response = await request.get("/usuarios/internal-advisors");

    if (response && !response.error) {
      if (response && !response.empty) {
        setInternalAdvisors(response);
      } else {
        setInternalAdvisors([]);
        setIsLoading(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }

    setIsLoading(false);
  };

  const fetchDevelopments = async () => {
    setDevelopments([]);
    setLoadingDevelopments(true);
    const response = await request.get("/desarrollos/get/all");
    if (response && !response.error) {
      if (response) {
        const options = response.map((development) => ({
          id: development.IDdesarrollo,
          value: development.nomDesarrollo,
          label: development.nomDesarrollo,
        }));

        setDevelopments(options);
        setLoadingDevelopments(false);
      } else {
        setDevelopments([]);
        setLoadingDevelopments(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
      setLoadingDevelopments(false);
    }
    setLoadingDevelopments(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitDisabled(true);

    const sanitizedPassword = contrasenna.replace(/[^a-zA-Z0-9]/g, "");
    const reversedPassword = sanitizedPassword.split("").reverse().join("");
    const encryptedPassword = MD5(reversedPassword).toString();

    const advisor = internalAdvisors.find(
      (a) => a.value === selectedInternalAdvisor
    );

    const developmentIds = selectedDevelopment.map((dev) => dev.id);

    let data = {
      parentuser: advisor?.id || userId,
      nombre: nombre,
      email: email,
      telefono: telefono,
      celular: telefono,
      tipoUsuario: tipoUsuario.value,
      comision: comision.value,
      contrasenna: encryptedPassword,
      tipoAsesor: tipoUsuario?.type,
      tipoRegistro: 0,
      password: contrasenna,
      register_id: userId,
      developments: developmentIds,
    };

    const response = await request.post("/usuarios/usuario/create", data);

    if (response && !response.error) {
      toggle();
      setIsSubmitDisabled(false);
      reload("Usuario creado con éxito ");
    } else {
      setIsSubmitDisabled(false);
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    if (modal) {
      LimpiarDatos();
    }
  }, [modal]);

  useEffect(() => {
    fetchInternalAdvisors();
    fetchDevelopments();
  }, []);

  return (
    <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
      <Modal isOpen={modal} toggle={toggle} size={size} centered>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody style={{paddingTop: "2px", paddingBottom: "2px"}}>
            <Row>
              <Col sm="12" md="12">
                <div className="project-box" style={{paddingBottom: "5px"}}>
                  <FormGroup className="row">
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Nombre:
                      </Label>
                      <Input
                        id="nombre"
                        type="text"
                        onChange={(e) => {
                          setNombre(e.target.value);
                        }}
                        required
                      />
                    </Col>
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Email:
                      </Label>
                      <Input
                        id="email"
                        type="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </Col>
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Teléfono:
                      </Label>
                      <Input
                        id="telefono"
                        type="tel"
                        value={telefono}
                        onChange={(e) => {
                          const telefono = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setTelefono(telefono);
                        }}
                      />
                    </Col>
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Comisión:
                      </Label>
                      <Select
                        value={comision}
                        options={commissions}
                        placeholder={"Seleccione..."}
                        onChange={(comision) => setComision(comision)}
                        required
                      />
                    </Col>
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Contraseña:
                      </Label>
                      <Input
                        id="contrasenna"
                        value={contrasenna}
                        type="text"
                        onChange={(e) => {
                          setContrasenna(e.target.value);
                        }}
                        minLength="6"
                        required
                      />
                    </Col>
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Tipo de usuario:
                      </Label>
                      <Select
                        value={tipoUsuario}
                        options={user_types}
                        placeholder={"Seleccione..."}
                        onChange={(tipoUsuario) => setTipoUsuario(tipoUsuario)}
                        required
                      />
                    </Col>
                    {tipoUsuario.value === "M" && userRole === "A" && (
                      <Col sm="12">
                        <Label className="col-form-label text-start">
                          Desarrollos que puede ver:
                        </Label>
                        <Select
                          isMulti
                          name="colors"
                          options={developments}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder={"Seleccione..."}
                          isLoading={loadingDevelopments}
                          onChange={(e) => setSelectedDevelopment(e)}
                        />
                      </Col>
                    )}
                    {tipoUsuario.value === "S" && userRole === "A" && (
                      <Col sm="12">
                        <Label className="col-form-label text-start">
                          Asesor interno:
                        </Label>
                        <Select
                          required
                          options={internalAdvisors}
                          placeholder={"Seleccionar asesor interno"}
                          onChange={(e) => setSelectedInternalAdvisor(e?.value)}
                          isLoading={isLoading}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 3,
                            }),
                          }}
                        />
                      </Col>
                    )}
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{color: "secondary", onClick: toggle}}>Cancelar</Btn>
            <Btn
              attrBtn={{
                type: "submit",
                color: "primary",
                disabled: isSubmitDisabled,
              }}
            >
              {isSubmitDisabled ? "Guardando..." : "Guardar"}
            </Btn>
          </ModalFooter>
        </Form>
      </Modal>
    </CommonModal>
  );
};

export default UsuarioForm;
